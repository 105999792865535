/* Base styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* src/App.css */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}



/* Animation for the logo */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Header styles */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px; /* Added padding for better spacing */
}

/* Link styles */
.App-link {
  color: #61dafb;
}

/* Keyframe animation for the logo */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive styles */

/* Mobile styles */
@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin); /* Adjust font size for smaller screens */
    padding: 10px; /* Adjust padding */
  }

  .App-logo {
    height: 30vmin; /* Adjust logo size for smaller screens */
  }
}

/* Tablet and up */
@media (min-width: 769px) and (max-width: 1024px) {
  .App-header {
    font-size: calc(9px + 2vmin); /* Slightly larger font size for tablets */
  }

  .App-logo {
    height: 35vmin; /* Adjust logo size for tablets */
  }
}

/* Desktop and up */
@media (min-width: 1025px) {
  .App-header {
    font-size: calc(10px + 2vmin); /* Default font size for desktops */
  }

  .App-logo {
    height: 40vmin; /* Default logo size for desktops */
  }
}