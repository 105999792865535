/* Ads.css */

.ads-body {
    margin: 0; /* Reset default margin */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: auto;
  }
  
  .ads-container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap into the next line */
    justify-content: space-between; /* Space items evenly */
    gap: 10px; /* Space between items */
    max-width: 80%; /* Optional: limit the width of the container */
    height: auto;
  }
  
  .ads-item {
    flex: 0 0 calc(50% - 10px); /* Two items per line with a gap */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    overflow: hidden;
    height: 500px; /* Set a fixed height */
    width: auto;
    margin: auto;
    padding: 10px;
  }
  
  .ads-item img {
    width: 100%; /* Make the image fill the container */
    height: 100%; /* Maintain the aspect ratio */
    border-radius: 8px; /* Optional: rounded corners */
    object-fit: contain;
  }
  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .ads-item {
      flex: 0 0 calc(100% - 10px); /* Take full width on small screens */
      height: auto; /* Adjust height to auto for flexibility */
    }
  }
  
  @media (max-width: 480px) {
    .ads-body {
      flex-direction: column; /* Stack ads vertically on very small screens */
      align-items: center; /* Center items horizontally */
    }
  
    .ads-container {
      justify-content: center; /* Center the container */
    }
  }
  