.product-dashboard {
  width: 100%; /* Set width to 100% for full utilization of space */
  max-width: 1000px; /* Optional max width for larger screens */
  margin: 0 auto; /* Center the dashboard */
  padding: 15px; /* Add padding to the dashboard */
  box-sizing: border-box; /* Include padding in the total width */
  overflow-x: hidden; /* Prevent horizontal overflow */
}
.dash-search-input {
  margin-bottom: 20px;
  padding: 8px 12px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.product-list {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Reduce gap for a more compact layout */
}

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; /* Reduced padding for smaller items */
  border: 1px solid #ddd;
  border-radius: 8px;
}

.product-details {
  flex: 1;
  font-size: 0.9rem; /* Slightly smaller font size */
}

.product-actions {
  display: flex;
  gap: 8px; /* Reduced gap between action buttons */
}

.edit-icon,
.delete-icon {
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
}

.edit-icon:hover {
  color: #007bff;
}

.delete-icon:hover {
  color: #dc3545;
}

button {
  padding: 8px 12px; /* Smaller padding for buttons */
  background-color: #12A89D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0e8c82;
}

.product-table {
  width: 100%; /* Ensure full width */
  border-collapse: collapse;
  margin-top: 20px;
}

.product-table th,
.product-table td {
  padding: 10px; /* Reduced padding for table cells */
  text-align: left;
  border: 1px solid #ddd; /* Add border to table cells */
  font-size: 0.9rem; /* Slightly smaller font size for table cells */
}

.product-table th {
  background-color: #f2f2f2; /* Header background color */
}

.product-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Striped effect for even rows */
}

.product-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.product-actions {
  display: flex;
  gap: 8px; /* Space between icons */
}

.edit-icon,
.delete-icon {
  margin: 5px; /* Reduce margin for icons */
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .product-dashboard {
    width: 100%; /* Set width to 100% for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  .product-item {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align items to the start */
  }

  .product-actions {
    justify-content: flex-end; /* Align actions to the right */
  }

  .product-table {
    font-size: 0.85rem; /* Adjust font size for smaller screens */
  }
}
