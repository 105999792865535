.slider-container {
  width: 80%; /* Adjust width as needed */
  margin: 10px auto;
  padding: 20px;
  position: relative;
  overflow: hidden; /* Hide overflow to ensure content does not spill */
}

.slick-slide-content {
  position: relative;
  width: 100%;
  height: 100%; /* Full height of the slider container */
  /*display: flex;
  justify-content: center;*/
}

.slick-slide-content img,
.slick-slide-content video {
  object-fit:fill; /* Cover the container proportionally */
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 450px;
}

.slide-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em; /* Adjust font size to fit within the image */
  width: calc(100% - 20px); /* Ensure text fits within the image */
  max-width: 50;
  text-align: center;
  box-sizing: border-box; /* Include padding in width calculation */
  z-index: 10s; /* Ensure text is above the content */
}

.slick-prev,
.slick-next {
  font-size: 0;
  width: 30px;
  height: 30px;
  background: #12A89D;
  border-radius: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 3; /* Ensure arrows are above the content */
}

.slick-prev:hover,
.slick-next:hover {
  background: #0E8C84;
}

.slick-prev:before,
.slick-next:before {
  font-size: 16px;
  color: white;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Adjust height for screens smaller than 768px */
@media (max-width: 768px) {
  .slider-container {
    height: auto; /* Adjusted height for smaller screens */
    padding: 2px; /* Optional: reduce padding for smaller screens */
  }

  .slide-text {
    font-size: 1em;
    padding: 5px; /* Optional: adjust padding for smaller screens */
  }
}
@media (max-width: 375px) {
  .slider-container {
    height: auto; /* Adjusted height for smaller screens */
    padding: 2px; /* Optional: reduce padding for smaller screens */
  }

  .slide-text {
    font-size: 1em;
    padding: 5px; /* Optional: adjust padding for smaller screens */
  }
}

