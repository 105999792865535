.products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Reduce gap to make it more compact */
  padding: 5px;
  justify-content: center; /* Center the product cards */
  height: auto; /* Allow the container to adjust to its content */
}

.search-input-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-input-container .search-input {
  width: 50%;
  padding: 8px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}




h3 {
  color: #12A89D;
}

strong {
  color: #12A89D;
}

.product-card {
  background-color: #f9f9f9; /* Light background for product cards */
  border: 1px solid #12A89D; /* Border for the cards */
  border-radius: 8px;
  padding: 3px; /* Reduced padding for smaller card */
  text-align: center;
  flex: 0 1 calc(50% - 8px); /* Allow two cards per row with gap adjustment */
  max-width: 400px; /* Reduced max-width for the card */
  width: 100%; /* Set to 100% to fit within flex item */
  transition: transform 0.2s;
  font-size: 0.8rem; /* Smaller text for better fit */
  box-sizing: border-box; /* Include padding and border in width calculation */
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: center;
}

.image-container {
  position: relative; /* Allows positioning of child elements */
  display: flex;
  align-items: center;
  justify-content: center; /* Center the image */
  border: 1px solid #12A89D; /* Fixed border */
  border-radius: 8px;
  overflow: hidden; /* Hide overflow */
  height: 160px; /* Set a fixed height for the image container */
  width: 220px;
}

.image-container img {
  width: 100%; /* Make the image take the full width of the container */
  height: 100%; /* Make the image take the full height of the container */
  object-fit: contain; /* Scale the image to cover the entire container */
}



.sizes-buttons {
  margin-top: 5px; /* Reduce space between product and buttons */
}

.sizes-buttons button {
  padding: 4px 8px; /* Smaller padding for buttons */
  margin-right: 4px; /* Reduce gap between buttons */
  border: 1px solid #12A89D;
  background-color: #f5f5f5;
  color: #12A89D;
  cursor: pointer;
  font-size: 0.75rem; /* Smaller button text */
  transition: background-color 0.3s ease;
}

.sizes-buttons button:hover {
  background-color: #ddd;
}

.sizes-buttons button.selected {
  background-color: #12A89D; /* Selected button style */
  color: white;
  border-color: #12A89D;
}

.arrow {
  position: absolute; /* Position them relative to the container */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  background-color: #ffffff; /* Semi-transparent background */
  border: 1px solid #12A89D;
  color: #12A89D;
  border-radius: 50%;
  cursor: pointer;
  padding: 6px; /* Reduced padding */
  z-index: 10; /* Ensure they are on top of the image */
  font-size: 0.7rem; /* Smaller font size */
}

.arrow:hover {
  color: #ffffff;
}

.arrow.left {
  left: 10px; /* Position to the left */
}

.arrow.right {
  right: 10px; /* Position to the right */
}

.arrow:disabled {
  opacity: 0.5; /* Make disabled buttons less visible */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
}

@media (max-width: 768px) {
  .product-card {
    flex: 0 1 calc(100% - 8px); /* Stack cards on smaller screens */
  }
  
  .arrow {
    padding: 4px; /* Further reduce padding for smaller screens */
    font-size: 0.5rem; /* Smaller font size for better fit */
  }
}
