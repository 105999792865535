.sticky-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between the buttons */
    z-index: 1000; /* Ensure buttons are above other content */
  }
  
  .sticky-button {
    background-color: #12A89D; /* Button background color */
    color: #fff; /* Icon color */
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }
  
  .sticky-button:hover {
    background-color: #0e7a69; /* Darker shade on hover */
  }
  
  .whatsapp {
    background-color: #25D366; /* WhatsApp color */
  }
  
  .phone {
    background-color: #34b7f1; /* Phone color */
  }
  