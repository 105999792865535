/* Login.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;
    padding: 20px;
}

.login-form {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
    position: relative; /* Ensure this is relative for absolute positioning inside */
}

label {
    display: block;
    margin-bottom: 5px;
    color: #555;
}

.logininput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.logininput:focus {
    border-color: #12A89D; /* Your header color */
    outline: none;
}

.login-button {
    background-color: #12A89D; /* Your header color */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.login-button:hover {
    background-color: #0e7b74; /* Darker shade for hover effect */
}

/* Adjustments for password field styles */
.password-group input {
    padding-right: 50px; /* More space for the button */
}

.toggle-password {
    position: absolute;
    right: 10px; /* Position the button inside the input */
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #12A89D; /* Your header color */
    cursor: pointer;
    font-size: 0.9em;
}

.toggle-password:hover {
    text-decoration: underline; /* Underline on hover */
}
