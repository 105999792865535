/* footer.css */

.footer-container {
  background-color: #c3cbd2;
  padding: 20px;
  text-align: center;
}

.social-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 100px; /* Space between icons */
}

.icon {
  margin: 0 10px;
  font-size: 24px;
  color: #333;
  text-decoration: none;
}

.info-row {
  display: flex;
  justify-content: space-around; /* Distribute columns evenly */
  border-top: 1px solid #ffffff;
}

.column {
  flex: 1;
  padding: 0 10px;
}

.footer-logo {
  width: 200px;
  height: auto;
  margin-bottom: 10px;
  padding: 10px;
}

.copyright-row {
  border-top: 1px solid #ffffff;
  padding-top: 10px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .social-row {
    flex-direction: column; /* Stack social icons vertically */
    gap: 10px; /* Reduced gap for better spacing */
  }

  .info-row {
    flex-direction: column; /* Stack info columns vertically */
    justify-content: center; /* Center items */
  }

  .column {
    padding: 10px 0; /* Add vertical padding for spacing */
  }

  .footer-logo {
    width: 150px; /* Reduce logo size */
  }
}

@media (max-width: 480px) {
  .footer-container {
    padding: 15px; /* Reduce padding for very small screens */
  }

  .icon {
    font-size: 20px; /* Reduce icon size */
  }

  .copyright-row {
    font-size: 14px; /* Smaller font size for copyright text */
  }
}
