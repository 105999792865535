.column-contacts {
  /* Add styles if needed */
}

/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
  .info-row-contacts {
    width: 80%; /* Full width for smaller screens */
    margin: 20px auto; /* Centered margin for smaller screens */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }
  
  .map {
    display: none; /* Hide the map on smaller screens */
  }

  .column-contacts-mob {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content within column */
    text-align: center; /* Center text */
  }
}
