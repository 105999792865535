/* General Styles */
.productformbody {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 20px;
}

.success-message-box {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid #c3e6cb;
}

.size-options {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
  gap: 20px; /* Adjusts space between size options */
}

.size-option {
  display: flex;
  flex-direction: column; /* Stack label, checkbox, and image input vertically */
  align-items: center; /* Center items horizontally */
  width: 150px; /* Set a fixed width for each size option */
}

/* Form Container */
form {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

/* Form Elements */
.productformdiv {
  margin-bottom: 15px; /* Space below each form element */
}

/* Adjust gap between all form elements */
.productformdiv,
label,
input[type="text"],
textarea,
input[type="checkbox"],
button {
  margin-bottom: 15px; /* Space between all elements */
}

/* Labels */
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input Fields */
input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Textarea specific styles */
textarea {
  resize: vertical; /* Allows users to resize vertically */
}

/* Checkbox Styles */
input[type="checkbox"] {
  margin-right: 10px;
}

/* Error Messages */
span {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
  display: block;
}

/* Button */
button {
  background-color: #12A89D; /* Use your header color */
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #0f8a7b; /* Darker shade for hover effect */
}

label{
  color: #12A89D;
}