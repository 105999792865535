/* Basic styles for sections */
.about-us {
  padding: 50px 20px;
}
h4 {
  color: #12A89D;
}

/* Right-to-left styles for Arabic */
.text-right {
  text-align: right;
  direction: rtl;
}

/* Left-to-right styles for English */
.text-left {
  text-align: left;
  direction: ltr;
}

.about-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 120px;
  border-bottom: 1px solid #12A89D;
  padding: 20px;
}

/* Initial styles for images and text */
.about-text,
.about-image {
  flex: 1;
  max-width: 60%;
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

/* Initial off-screen positions */
.about-text {
  transform: translateX(100%); /* Start off-screen to the left */
}

.about-image {
  transform: translateX(-100%); /* Start off-screen to the right */
}

/* Animation classes for sliding in */
.slide-in-left {
  transform: translateX(0); /* Slide to initial position */
  opacity: 1; /* Fully visible */
}

.slide-in-right {
  transform: translateX(0); /* Slide to initial position */
  opacity: 1; /* Fully visible */
}

/* Animation classes for sliding out */
.slide-out-left {
  transform: translateX(-100%); /* Slide out to the left */
  opacity: 0; /* Fully transparent */
}

.slide-out-right {
  transform: translateX(100%); /* Slide out to the right */
  opacity: 0; /* Fully transparent */
}

/* Media Queries for Responsive Design */

/* For screens smaller than 768px */
@media (max-width: 768px) {
  .about-section {
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center align items */
      margin-bottom: 60px; /* Reduce bottom margin */
  }

  .about-text,
  .about-image {
      max-width: 100%; /* Allow full width */
      opacity: 1; /* Ensure text and image are visible on load */
      transform: translateX(0); /* Reset transform for mobile */
  }

  h4 {
      font-size: 1.5em; /* Adjust heading size for smaller screens */
  }
}

/* For screens smaller than 375px */
@media (max-width: 375px) {
  .about-us {
      padding: 30px 10px; /* Reduce padding */
  }

  .about-section {
      padding: 10px; /* Reduce padding */
  }

  h4 {
      font-size: 1.2em; /* Further reduce heading size */
  }

  .about-text {
      font-size: 0.9em; /* Adjust text size for readability */
  }

  .about-image img {
      width: 100%; /* Ensure images are responsive */
      height: auto; /* Maintain aspect ratio */
  }
}
