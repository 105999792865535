/* Ensure proper box-sizing */
* {
  box-sizing: border-box;
}

.dark-background {
  background-color: #333; /* Dark background color */
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for larger screens */
  gap: 10px; /* Gap between columns */
  padding: 20px; /* Padding inside the div */
  margin: 50px auto; /* Center the container with automatic left/right margins */
  width: 100%; /* Set width to 90% to give some space on the sides */
  height: auto;
}

/* Infocolumn styles */
.infocolumn {
  border-radius: 25px;
  display: flex;
  background-color: #444; /* Slightly lighter background for each column */
  color: white;
  padding: 10px;
  text-align: center; /* This is optional when using flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-size: 20px;
}

.col2{
    direction: rtl;
    unicode-bidi: embed; /* Ensures that embedded English text is aligned within the RTL layout */
    text-align: justify; /* Aligns both languages in a more balanced way */
    padding: 10px;  
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .dark-background {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets and smaller screens */
  }

  .infocolumn {
      font-size: 20px; /* Slightly smaller font for smaller screens */
      padding: 15px; /* Adjust padding if necessary */
  }
}

@media (max-width: 480px) {
  .dark-background {
      grid-template-columns: 1fr; /* 1 column for mobile devices */
  }

  .infocolumn {
      font-size: 18px; /* Even smaller font for mobile */
      padding: 10px; /* Adjust padding if necessary */
  }
}
