/* General header styles */
header {
  background-color: #12a89d;
  padding: 5px 5px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: block;
  margin: 10px;
}
/* Logo styles */
.logo img {
  width: 120px;
  height: auto;
}

/* Navigation links styles */
.nav-links {
  list-style: none;
  display: flex;
  gap: 25px;
  margin: 0;
  padding: 0;
}

.language-switcher {
  display: flex;
  gap: 10px;
  margin: 10px;
}

.right-dropdown-content {
  display: none; /* Initially hidden */
  position: absolute;
  left: 100%; /* Positions the dropdown to the left */
  top: 0;
  min-width: 300px;
  background-color: #ffffff; /* Background color */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px; /* Optional: for rounded corners */
  z-index: 1; /* Ensure it appears above other content */
}
.left-dropdown-content {
  display: none; /* Initially hidden */
  position: absolute;
  right: 100%; /* Positions the dropdown to the left */
  top: 0;
  min-width: 250px;
  background-color: #ffffff; /* Background color */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px; /* Optional: for rounded corners */
  z-index: 1; /* Ensure it appears above other content */
}
/* Show left dropdown on hover over poultry-dropdown */
.poultry-dropdown:hover .left-dropdown-content {
  display: block; /* Show dropdown when hovering over the parent */
}

.poultry-dropdown:hover .right-dropdown-content {
  display: block; /* Show dropdown when hovering over the parent */
}
.language-switcher button {
  color: #fff;
  background-color: #12a89d;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.language-switcher button:hover {
  background-color: #fff;
  color: #12a89d;
}

.nav-links li {
  position: relative;
  display: inline;
}

.dropbtn-poultry {
  color: #12a89d; /* Initial text color */
  background-color: #ffffff; /* Initial background color */
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 4px; /* Optional: for rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}
.dropbtn-poultry:hover {
  background-color: #12a89d; /* Background color on hover */
  color: #ffffff; /* Text color on hover */
  display: block;
}
.nav-links a,
.dropbtn {
  /* Apply same styles to both nav links and dropbtn */
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-links a:hover,
.dropbtn:hover
{
  background-color: #ffffff;
  color: #12a89d;
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.poultry-dropdown{
  position: relative;
}

.dropdown-content {
  display: none; /* Initially hidden */
  position: absolute;
  background-color: #ffffff; /* Adjust as needed */
  min-width: 160px; /* Adjust as needed */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1; /* Ensure dropdown is above other content */
  border-radius: 4px; /* Optional: for rounded corners */
}

.poultry-dropdown-content {
  display: none; /* Initially hidden */
  position: absolute;
  background-color: #ffffff; /* Adjust as needed */
  min-width: 160px; /* Adjust as needed */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1; /* Ensure dropdown is above other content */
  border-radius: 4px; /* Optional: for rounded corners */
}
.dropdown-content a {
  color: #12a89d; /* Link color */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.poultry-dropdown-content a {
  color: #12a89d; /* Link color */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.left-dropdown {
  display: none;
}

.dropdown-content-left-dropdown {
  display: none;
  position: absolute;
  right: 100%; /* Positions the dropdown to the left */
  top: 0;
  min-width: 160px;
}

.dropdown-content a:hover {
  background-color: #12a89d; /* Background color on link hover */
  color: #ffffff; /* Text color on link hover */
}



/* Show dropdown on hover (desktop) */
.dropdown:hover .dropdown-content {
  display: block;
}

.poultry-dropdown:hover .poultry-dropdown-content {
  display: block;
}

/* Hamburger menu styles */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Show hamburger on smaller screens */
  }

  .nav-links {
    display: none; /* Hide nav links by default */
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px; /* Adjust according to header height */
    left: 0;
    background-color: #12a89d;
    padding: 10px 0;
    max-height: 80vh; /* Limit height to 80% of viewport */
    overflow-y: auto; /* Enable vertical scroll if content exceeds 80vh */
  }

  .nav-links.show {
    display: flex; /* Show nav links when hamburger is open */
  }

  .nav-links li {
    text-align: center;
    margin: 10px 0;
  }

  /* Styling for dropdown and links */
  .dropdown-content {
    position: static;
    background-color: #12a89d;
    color: white; /* Text color for the links */
  }

  .dropdown-content a {
    display: block;
    padding: 10px;
    color: white; /* Text color for the links */
    text-decoration: none;
  }

  .dropdown-content a:hover {
    background-color: #ffffff; /* Background color on hover */
    color: #12a89d; /* Text color on hover */
  }

  .language-switcher-small {
    display: flex;
    flex-direction: column;
    margin-top: 10px; /* Space below the hamburger menu */
  }

  .language-switcher {
    display: none; /* Hide the large screen language switcher */
  }

  .poultry-dropdown {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .dropbtn-poultry {
    display: block;
    background-color: #12a89d;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }

  /* Submenu (poultry subcategories) */
  .left-dropdown-content,
  .right-dropdown-content {
    display: none;
    position: absolute;
    background-color: #12a89d;
    color: #ffffff;
    width: 200px; /* Adjust submenu width */
    z-index: 1;
    top: 100%; /* Position directly below the poultry item */
    left: 0;
  }

  /* Show submenu when hovering over the poultry item */
  .poultry-dropdown:hover .left-dropdown-content,
  .poultry-dropdown:hover .right-dropdown-content {
    display: block; /* Show dropdown on hover */
    top: 100%; /* Ensure it's below the poultry menu */
  }

  /* Styling for links in the submenu */
  .left-dropdown-content a,
  .right-dropdown-content a {
    padding: 10px;
    color: white;
    text-decoration: none;
  }

  /* Hover effect for submenu items */
  .left-dropdown-content a:hover,
  .right-dropdown-content a:hover {
    background-color: #ffffff; /* Background color on hover */
    color: #12a89d; /* Text color on hover */
  }

  /* Optional: Add some spacing between submenu items */
  .left-dropdown-content a,
  .right-dropdown-content a {
    padding: 8px 16px;
  }
}


