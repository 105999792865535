.news-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Space between each news card */
    padding: 20px;
    width: 80%; /* Ensure the container takes full width */
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  .news-card {
    background-color: #ffffff; /* White background for news cards */
    border: 1px solid #ddd; /* Light border for separation */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    width: 100%; /* Ensure each card takes full width of the container */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }
  
  .news-heading {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333; /* Dark color for the heading */
  }
  
  .news-report {
    font-size: 1em;
    margin-bottom: 15px;
    color: #666; /* Medium color for the report */
  }
  
  .news-date {
    font-size: 0.9em;
    color: #999; /* Light color for the date */
    text-align: right; /* Align date to the right */
  }
  